/*General*/
body {
  margin: 0;
}
* {
  font-family: "Poppins", sans-serif;
}
/*EndGeneral*/

/*Grid*/
.container {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 75px 60px 75px 1fr;
}
/*EndGrid*/

/*GridComponents*/
.header_back {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 4;
  overflow: hidden;
}
.button_secondary {
  grid-column-start: 10;
  grid-column-end: 12;
  grid-row-start: 2;
  grid-row-end: 3;
}
.data_home {
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 3;
  grid-row-end: 6;
  padding: 25px;
  border-radius: 10px;
  background-color: #f1f1f1;
  box-shadow: 0px 2px 4px #0000004a;
  height: auto;
}
/*EndGridComponents*/

/*GeneralStyles*/
.header_back img {
  width: 100%;
  height: auto;
}

.title_links {
  display: flex;
  width: 100%;
}
.title_links h5 {
  width: 50%;
}
.title_links a {
  text-decoration: underline;
  margin: 30px 0 10px 0;
  text-align: right;
  width: 50%;
  color: #89bd02;
}
/*EndGeneralStyles*/

.button_query {
  display: flex;
  grid-column-start: 6;
  grid-column-end: 12;
  grid-row-start: 2;
  grid-row-end: 3;
}

.button_query_one {
  display: flex;
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 2;
  grid-row-end: 3;
}

.button_query button {
  width: 100%;
  margin: 0px 8px;
}

.button_query_one button {
  width: 100%;
  margin: 0px 8px;
}
