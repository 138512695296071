/*Grid*/
.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
/*EndGrid*/

/*Colors*/
:root {
  --color-text: #2c2e35;
  --color-primary: #00903f;
  --color-secondary: #89bd02;
  --color-orange: #ffb46d;
  --color-grayBackground: #f0f0f0;
  --color-grayDark: #7a7a7a;
  --color-red: #f93a70;
}
/*EndColors*/

/*General*/
button {
  cursor: pointer;
}
/*EndGeneral*/

/*Input*/
input {
  margin: 8px 0;
  padding: 0 0 0 40px;
  border-radius: 10px;
  border: solid 1px white;
  height: 45px;
  width: 86%;
  cursor: text;
}
.input {
  position: relative;
}
.input i {
  position: absolute;
  left: 15px;
  top: 23px;
  color: var(--color-grayDark);
}
/*EndInput*/

/*Select*/
select {
  margin: 8px 0;
  padding: 0;
  border-radius: 10px;
  border: solid 1px white;
  height: 45px;
  width: 100%;
  cursor: text;
}
.select {
  position: relative;
}
.select i {
  position: absolute;
  left: 15px;
  top: 23px;
  color: var(--color-grayDark);
}
/*Select*/

/*LogoHeader*/
.logo {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  justify-self: center;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  z-index: 1;
  margin: 10px;
  width: 75px;
}
.logo img {
  height: 100%;
  width: auto;
}
/*EndLogoHeader*/

/*Buttons*/
button {
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
  height: 45px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  box-shadow: 0px 2px 4px #0000004a;
}
.button_primary {
  color: white;
  background-color: var(--color-primary);
}
.button_secondary {
  color: var(--color-primary);
  background-color: white;
}
.button_table {
  width: 20%;
  margin-left: 40%;
  margin-right: 40%;
}
.button_header {
  margin: 0;
}
/*EndButtons*/

/*Links*/
a {
  width: 100%;
  color: var(--color-primary);
  font-size: 0.8em;
  margin: 10px 0;
}
/*EndLinks*/

/*Links*/
a {
  width: 100%;
  color: var(--color-primary);
  font-size: 0.8em;
  margin: 10px 0;
}
/*Titles*/
h4 {
  margin: 0px 0 40px 0;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-text);
}
h5 {
  margin: 30px 0 10px 0;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--color-primary);
}
/*EndTitles*/
/*Cards*/
.card {
  margin: 10px 0;
  position: relative;
  border-radius: 8px;
  background-color: white;
  height: 55px;
  display: flex;
  box-shadow: 0px 2px 4px #0000001f;
}
.card .stateInProgress {
  margin: 0 20px 0 0;
  border-radius: 8px 0 0 8px;
  height: 55px;
  width: 10px;
  background-color: var(--color-orange);
}
.card .stateFinished {
  margin: 0 20px 0 0;
  border-radius: 8px 0 0 8px;
  height: 55px;
  width: 10px;
  background-color: var(--color-secondary);
}
.card span {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-text);
}
.card p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text);
}
.card .information {
  margin: 2px 20px;
}
.card .cardLinks {
  top: 14px;
  right: 20px;
  position: absolute;
  width: auto;
}
.card .cardLinks a {
  margin: 0 20px;
  text-align: right;
}
/*EndCards*/

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3small {
  margin-bottom: 5px;
}

.pointer {
  cursor: pointer;
}

/*Loader*/

.container_loader {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.loading {
  margin-top: 20%;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  overflow: hidden;
  justify-self: center;
  align-self: center;
}

.loading p {
  text-align: center;
  font-size: 1rem;
}

.loader {
  margin-left: auto;
  margin-right: auto;
  border: 7px solid #f3f3f3; /* Light grey */
  border-top: 7px solid #00903f; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*EndLoader*/

.input_error {
  border: solid 1px #f93a70 !important;
  color: #f93a70;
}

.input_right {
  border: solid 1px #89bd02 !important;
}

.m-0 {
  margin: 0px;
}

.center-text {
  text-align: center;
}

.rechazado_link {
  width: 100%;
  color: var(--color-primary) !important;
  font-size: 0.8em !important;
  margin: 10px 0 !important;
  cursor: pointer;
}