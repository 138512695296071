/*General*/
body {
  margin: 0;
}
* {
  font-family: "Poppins", sans-serif;
}
/*EndGeneral*/

/*Grid*/
.container {
  display: grid;
  grid-template-rows: 75px 60px 75px auto;
  grid-template-columns: repeat(12, 1fr);
}
/*EndGrid*/

/*GridComponents*/
.header_back img {
  width: 100%;
  height: auto;
}
.header_back {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 4;
  overflow: hidden;
}
.data_review {
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 3;
  grid-row-end: 5;
  padding: 25px;
  border-radius: 10px;
  background-color: #f1f1f1;
  box-shadow: 0px 2px 4px #0000004a;
}
/*EndGridComponents*/

/*GeneralStyles*/
.header_back {
  display: flex;
}
.data_review .back {
  width: 3%;
  margin: 0;
  background-color: none;
  box-shadow: none;
}
.data_review .back img {
  height: 20px;
  width: auto;
}
.data_review h4 {
  width: 97%;
  margin-top: 3px;
  margin-left: 5px;
}
.data_inf {
  display: flex;
}
.data_review .card {
  width: 84%;
  margin-right: 5px;
}
.button_review {
  width: 16%;
  display: flex;
}
.button_review button {
  margin: 10px 5px;
  height: 55px;
  box-shadow: none;
}
.button_review .cancel {
  background-color: var(--color-red);
}
.button_review .resend {
  background-color: var(--color-orange);
}
.cards_inf {
  display: flex;
}
.cards_ver {
  display: flex;
  width: 100%;
}
.card_img {
  margin: 0.5%;
  padding: 15px;
  border-radius: 10px;
  width: 90%;
  height: 300px;
  background-color: white;
}
.card_table {
  margin: 0.5%;
  padding: 15px;
  border-radius: 10px;
  width: 47%;
  height: auto;
  background-color: white;
}
.card_tableU {
  margin: 0.5%;
  padding: 15px;
  border-radius: 10px;
  width: 47%;
  height: auto;
  background-color: white;
}
.card_table input{
  padding: 0 0 0 10px;
}
.card_table .table{
  height: "auto";
  max-height: "399px";
  overflow-y: "auto";
}
.card_table table td input{
  border: 1px solid #bdbdbd;
  width: 90%;
  box-sizing: border-box;
  padding: 0 0 0 10px;
}
.card_table table td button{
  background-color: transparent;
  border: none;
  box-shadow: none;
  height: 10px;
  margin: 5px 0px;
}
.card_table table th {
  height: 10px;
}
.card_table .text_img
{
  align-items: center;
  width: 100%;
  display: flex;
  height: 50px;
}
.card_img .text_img {
  margin: 10px;
  width: 100%;
  display: flex;
}
.text_img p
{
  margin: 0;
  font-size: 0.9rem;
  flex: auto;
  text-align: left;
}
.text_img button
{
  flex: auto;
  padding: 0 15px;
}
.card_img .text_img p,
.card_img .text_img a {
  margin: 0;
  width: 48%;
  font-size: 0.9rem;
}
.card_table .text_img,
.card_img .text_img a {
  text-align: right;
}
.card_table .text_img,
.card_img .text_img {
  color: var(--color-primary);
  font-weight: 600;
}
.card_img .img_card {
  height: 260px;
  width: 100%;
  overflow: hidden;
}
.card_img img {
  width: 100%;
  height: auto;
}
.card_signature1 {
  width: auto;
  height: auto;
}
.img_signature {
  display: flex;
}
.img_signature .card {
  width: 70%;
  box-shadow: none;
}
.img_signature .signature {
  width: 30%;
  height: 75px;
}
.img_signature .signature img {
  width: auto;
  height: 100%;
}
.card_signature2 .card {
  height: auto;
  box-shadow: none;
  display: block;
}
.card_signature2 .signature {
  height: 140px;
  overflow: hidden;
}
.card_signature2 .signature img {
  height: 100%;
  width: auto;
}
.card_table .text_img .inputCifin{
  width: 20%;
  border: solid 1px #bdbdbd;
}
.selectCifin{
  border: solid 1px #bdbdbd;
  width: 80px;
}
/*EndGeneralStyles*/
