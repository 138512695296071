/*General*/
body {
  margin: 0;
}
* {
  font-family: "Poppins", sans-serif;
}
/*EndGeneral*/

/*Grid*/
.container {
  display: grid;
  grid-template-rows: 75px 60px 75px auto;
}
/*EndGrid*/

/*GridComponents*/
.header_back img {
  width: 100%;
  height: auto;
}
.header_back {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 4;
  overflow: hidden;
}
.data_records {
  position: relative;
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 3;
  grid-row-end: 5;
  padding: 25px;
  border-radius: 10px;
  background-color: #f1f1f1;
  box-shadow: 0px 2px 4px #0000004a;
}
/*EndGridComponents*/

/*GeneralStyles*/
.header_back {
  display: flex;
}
.data_records .back {
  width: 3%;
  margin: 0;
  background-color: none;
  box-shadow: none;
}
.data_records .back img {
  height: 20px;
  width: auto;
}
.filter {
  display: flex;
}
.filter h5 {
  margin: 0;
}
.filter input {
  width: 200px;
  margin: 0 10px;
}
.filter .input i {
  left: 20px;
  top: 15px;
}
.filter .date {
  padding-left: 10px;
}
.filter button {
  width: 210px;
  margin: 0 10px;
}
table {
  width: 100%;
  margin: 10px 0 20px 0;
  border-spacing: 0;
  border-radius: 10px;
}
.title_table {
  background-color: white;
}
th {
  height: 50px;
  font-size: 0.8rem;
  color: var(--color-text);
}
td {
  text-align: center;
  height: 40px;
  font-size: 0.8rem;
  color: var(--color-text);
}
tr {
  background-color: white;
}
tr:nth-child(even) {
  background-color: #f4f4f4;
}
tr a {
  font-size: 0.8rem;
}
.pager {
  position: absolute;
  bottom: 5px;
  right: 3%;
  display: flex;
}
.pager button {
  width: 30px;
  height: 30px;
  box-shadow: none;
  margin: 0;
  background-color: none;
}
.pager button img {
  width: 100%;
  height: auto;
}
.pager p {
  margin: 3px 0 0 0;
  font-size: 0.9rem;
}
/*EndGeneralStyles*/
