/*General*/
body {
  margin: 0;
}
* {
  font-family: "Poppins", sans-serif;
}
/*EndGeneral*/

/*Grid*/
.container {
  display: grid;
  grid-template-rows: 70px 70px 100px 440px 100px;
}
/*EndGrid*/

/*GridComponents*/
.contact {
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 2;
  justify-self: end;
  align-self: center;
}
.menu {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: var(--color-grayBackground);
  text-align: right;
  padding: 0 8.3%;
}
.img_banner {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 3;
  grid-row-end: 6;
  overflow: hidden;
}
.text_banner {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 4;
  grid-row-end: 5;
  align-self: center;
}
.login {
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 4;
  grid-row-end: 5;
  place-self: center;
  background-color: #f0f0f0;
  margin: 0 40px;
  border-radius: 10px;
  box-shadow: 0px 7px 12px #949494;
  padding: 20px 40px;
  text-align: center;
  width: 300px;
}
/*EndGridComponents*/

/*GeneralStyles*/
.contact ul {
  display: inline-flex;
  list-style: none;
  color: var(--color-primary);
}
.contact ul li {
  width: 100%;
  margin: 0 10px;
}
.contact ul .text {
  font-weight: 200;
  color: var(--color-grayDark);
}
.menu ul {
  display: inline-flex;
  list-style: none;
  color: var(--color-text);
}
.menu ul li {
  margin: 10px 10px 0 10px;
  font-size: 14px;
}
.img_banner img {
  height: 100%;
  width: auto;
}
.text_banner h3 {
  color: white;
  font-weight: 600;
  font-size: 3em;
  margin: 16px 0;
}
.text_banner p {
  color: white;
  font-weight: 200;
  font-size: 1em;
}
.login h4 {
  margin: 10px 0;
  font-size: 1.3em;
  font-weight: 600;
}
.login p {
  margin: 10px 0;
  font-size: 0.9em;
  font-weight: 300;
}
/*EndGeneralStyles*/
