/*General*/
html,
body {
  margin: 0;
  height: 100%;
}
* {
  font-family: "Poppins", sans-serif;
}
/*EndGeneral*/

/*Grid*/
.container {
  height: 100%;
  display: grid;
  grid-template-rows: 100%;
}
/*EndGrid*/

/*GridComponents*/
.back_modal {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: rgba(0, 0, 0, 0.349);
  filter: blur(2.5px);
}
.modal {
  grid-column-start: 3;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 2;
}
/*EndGridComponents*/

/*GeneralStyles*/
.card_modal {
  border: solid 1px #bdbdbd;
  border-radius: 8px;
  box-shadow: none;
  height: auto;
  display: -webkit-box;
}
.modal {
  position: relative;
  margin: 0px;
  background-color: white;
  border-radius: 10px;
  height: auto;
  padding: 60px 0px;
  z-index: 2;
  margin-top: auto;
  margin-bottom: auto;
}
.information_modal {
  width: 20%;
  box-shadow: none;
}

.information_modal span {
  font-size: 0.6rem;
}

.information_modal p {
  font-size: 0.8rem;
}
.x_modal {
  position: absolute;
  color: #00903f !important;
  top: 0px;
  right: 10px;
  background-color: transparent;
  height: 20px;
  width: 20px;
  box-shadow: none;
}
.x_modal img {
  width: 30px;
  height: 30px;
}

.modal h4 {
  text-align: center;
}

.modal_textarea {
  border: solid 1px #bdbdbd !important;
  width: 100%;
  font-size: 0.7rem !important;
  height: 100px !important;
  resize: none !important;
  color: var(--color-text) !important;
  border-color: var(--color-text) !important ;
}

.modal_textarea:focus {
  border: solid 1px #bdbdbd !important;
  box-shadow: none !important;
  outline: none !important;
}

.buttons_modal {
  display: flex !important;
}

.buttons_modal button {
  width: 30% !important;
  margin: 2% !important;
}
/*EndGeneralStyles*/
.title_modal {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  color: var(--color-text) !important;
}

.modal_button {
  border-radius: 10px !important;
  width: 30% !important;
  margin: 20px 0 !important;
  height: 45px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 0px 2px 4px #0000004a !important;
}
.modal_button_primary {
  color: white !important;
  background-color: var(--color-primary) !important;
}
.modal_button_secondary {
  color: var(--color-primary) !important;
  background-color: white !important;
}
