/*General*/
body {
  margin: 0;
}
* {
  font-family: "Poppins", sans-serif;
}

/*EndGeneral*/

/*Grid*/
.container {
  margin-bottom: 50px;
  display: grid;
  grid-template-rows: 70px 70px 100px 440px 1fr;
  grid-template-columns: repeat(12, 1fr);
}
/*EndGrid*/

/*GridComponents*/
.header_back {
  display: flex;
}

.header_back img {
  width: 100%;
  height: auto;
}

.header_back {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 4;
  overflow: hidden;
}

.query_data {
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 3;
  grid-row-end: 6;
  padding: 25px 25px 0 25px;
  border-radius: 10px;
  background-color: #f1f1f1;
  box-shadow: 0px 2px 4px #0000004a;
}

.hov:hover {
  cursor: pointer;
}

.query_data .back {
  width: 3%;
  margin: 0;
  background-color: none;
  box-shadow: none;
}
.query_data .back img {
  height: 20px;
  width: auto;
}

/*EndGridComponents*/

/*GeneralStyles*/
.steps {
  display: flex;
  width: 100%;
  height: 50px;
  cursor: pointer;
}
.steps div {
  margin: 0 0.4%;
  border-radius: 8px;
  width: 32.5%;
  height: 100%;
  text-align: center;
  box-shadow: 0px 2px 4px #0000001f;
}

.input {
  position: relative;
}
.input i {
  position: absolute;
  left: 15px;
  top: 23px;
  color: var(--color-grayDark);
}

.steps p {
  font-size: 1rem;
  margin: 0;
  padding: 12px 10px;
}
.steps p b {
  margin-right: 10px;
  font-size: 1.2rem;
  font-weight: 600;
}
.steps .active {
  background-color: white;
  color: var(--color-primary);
}
.steps .off {
  background-color: #d8d8d8;
  color: var(--color-text);
}
.step_one,
.step_two {
  display: flex;
  padding: 20px;
  background-color: white;
  margin: 20px 0.4% 0 0.4%;
  width: 95%;
}
.step_three {
  padding: 20px;
  background-color: white;
  margin: 20px 0.4% 0 0.4%;
  width: 95%;
}
.step_three .terms h4 {
  text-align: center;
  font-size: 1.1rem;
}
.step_three .terms p {
  font-size: 0.7rem;
}
.instructions {
  width: 31%;
  margin-right: 2%;
}
#zonadestino {
  position: relative;
  height: 200px;
  max-height: 150px;
  width: 67%;
  border-style: dashed;
  border-width: 2px;
  background-color: #e9e9e9;
  border-color: #d0d0d0;
  text-align: center;
  padding-top: 100px;
  color: var(--color-primary);
}
#zonadestino p {
  position: absolute;
  top: 90px;
  left: 34%;
  color: #bdbdbd !important;
}
#zonadestino img {
  position: absolute;
  top: 80px;
  left: 20%;
}
.terms_data {
  display: flex;
}
.terms_data h4 {
  margin: 0;
  font-size: 1rem;
}
.terms_data input {
  border: solid 1px #bdbdbd;
  max-width: 100%;
  margin: 8px 5px;
}
.terms_data .name {
  width: 40%;
  margin: 0 1%;
}
.text_name {
  display: block;
}
.last_name {
  display: flex;
}
.last_name .input {
  display: flex;
  width: 100%;
}
.id_elements {
  display: block;
  max-width: 100%;
}
.id_elements .select {
  width: 100%;
  margin: 0 1%;
  position: relative;
}
.id_elements .select i {
  position: absolute;
  left: 15px;
  top: 23px;
  color: var(--color-grayDark);
}
.id_elements .select select {
  margin: 8px 0;
  padding: 0 0 0 40px;
  border-radius: 10px;
  border: solid 1px #bdbdbd;
  height: 47px;
  width: 100%;
}
.id_elements .select select .disabled {
  color: #bdbdbd;
}
.id_elements .input {
  width: 95%;
}
.terms .signature_acept {
  width: 25%;
}
.terms_data #zonadestino {
  width: 28%;
  margin: 33px 30px;
  height: 106px;
  padding: 0;
}
.terms_data #zonadestino p {
  margin: 0;
  top: 3px;
  left: 3px;
}
.accept_terms_button {
  text-align: left;
  width: 100%;
  text-align: center;
}
.accept_terms_button .check label {
  font-size: 0.8rem;
}
.accept_terms_button .check input {
  margin: 20px 0;
  padding: 0;
  border-radius: 20px;
  border: 1px;
  border-color: var(--color-primary);
  height: 15px;
  width: 20px;
}
.accept_terms_button .buttons_terms {
  margin-left: 30.5%;
  width: 40%;
  display: flex;
}
.accept_terms_button .buttons_terms button {
  width: 100%;
  margin: 0 10px;
}
/*EndGeneralStyles*/
